import './app.css';
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Footer from './components/Footer';

function App() {
    return (
        <div className="App">
            <Header sublogo={'Autocueillette'} /*date={`du 25 novembre \n au 10 décembre`}*//>
            <MainContent/>
            <Footer/>
        </div>
    );
}

export default App;
