export default function Autopick(props) {

    return (
        <section className={'autopick'}>
            <div className="wrapper">
                <h2>L'autocueillette&nbsp;: Le déroulement</h2>
                <h4>La cueillette du sapin de Noël est une expérience magique à partager en famille. Appropriez-vous cette
                    tradition et venez choisir celui qui vous interpelle le plus parmi des centaines d’arbres mesurant entre
                    5 et 9 pieds.</h4>
            </div>
        </section>
    );
}