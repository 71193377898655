import React from "react";
import Pulse from '../img/pulse.svg';

class LazyImage extends React.Component {

    constructor(props) {
        super(props);
        this.src = props.src;
        this.toObserve = React.createRef();
        this.state = {loaded: 'false'};
        this.style = props.style;
    }

    componentDidMount() {
        let callback = function(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let img = entry.target.querySelector('.lazyloaded-image');
                    img.src = img.getAttribute('data-src');
                    observer.disconnect();
                }
            });
        };

        const observer = new IntersectionObserver(callback);

        let target = this.toObserve.current;
        observer.observe(target);
    }

    render() {
        return(
            <div ref={this.toObserve} data-loaded={this.state.loaded} className={'lazyload-wrapper'}>
                <img style={this.style} className={'placeholder-img'} width={200} height={200} src={Pulse} alt=""/>
                <img style={this.style} className={'lazyloaded-image'} onLoad={() => this.setState({loaded: true})} data-src={this.src} />
            </div>
        )
    }
}

export default LazyImage;