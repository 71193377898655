import { ReactComponent as PineSVG } from "../img/pine.svg";

function FindTree(props) {


    return (
        <section className={'find-tree'}>
            <span className={'anchor'} id={'sapin-baumier'}></span>
            <h2>Trouvez votre sapin Baumier</h2>
            <h4>Si l’on se fie aux ventes d’arbres de Noël, le baumier semble définitivement être le préféré des
                Québécois! Cette variété de sapin possède une odeur assez prononcée, en plus d’avoir une bonne densité
                d’épines et de branches. De plus, la couleur «vert sapin» est souvent associée à celle du Baumier.</h4>
            <PineSVG/>
        </section>
    );
}

export default FindTree;