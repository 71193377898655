function Navigation(props) {
    return(
        <nav role={"navigation"} data-show={props.dataShow}>
            <ul>
                <li><a href="#autocueillette">L'autocueillette</a></li>
                <li><a href="#sapin-baumier">Sapin Baumier</a></li>
                <li><a href="#tarification">Tarification</a></li>
                {/*<li><a href="https://levisbosapin.simplybook.me/v2/" target={'_blank'} className="reserve-place">Réservation</a></li>*/}
            </ul>
        </nav>
    )
}

export default Navigation
