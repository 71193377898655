import '../fold.css';
import bgVidMp4 from '../img/conte-vid-20-compressed.mp4';
import bgVidWeb from '../img/conte-vid-20-compressed.webm';
import bgImgMob from '../img/header-bg.png';
import logo from '../img/bosapin-logo-header.png';
import Popup from "./Popup";
import {useState} from "react";
import Pulse from '../img/pulse.svg';
import Banner from "./Banner";

function Header(props) {

  const [ready, setReady] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isRedirected = urlParams.get('redirected');

  window.addEventListener('load', () => {
    setReady(true);
  });

  function render() {
    if (!ready) {
      return (
        <header>
          <section className={'loader'} data-hide={'false'}>
            <img src={Pulse} width={200} height={200} alt=""/>
          </section>
          <img src={bgImgMob} alt=""/>
          <video muted={true} autoPlay={true} loop={true}>
            <source src={bgVidWeb} type="video/webm;"/>
            <source src={bgVidMp4} type="video/mp4"/>
          </video>
        </header>
      );
    } else {
      return (
        <header>
          {/*<Banner text={'Nous sommes complets pour la saison 2021. Vos billets de confirmation seront exigés à l’entrée. Merci de votre compréhension et joyeuses Fêtes!'}/>*/}
          <section className={'loader'} data-hide={'true'}>
            <img src={Pulse} width={200} height={200} alt=""/>
          </section>
          {isRedirected &&
          <Popup/>
          }
          <div className="bg-wrapper">
            <img src={bgImgMob} alt=""/>
            <video muted={true} autoPlay={true} loop={true}>
              <source src={bgVidWeb} type="video/webm;"/>
              <source src={bgVidMp4} type="video/mp4"/>
            </video>
          </div>
          <div className="content-wrapper">
            <div className={'logo-wrapper'}>
              <img src={logo} alt='' />
            </div>
            <div className={'date-information'}>
              {props.date}
            </div>
          </div>
        </header>
      );
    }
  }

  return render();
}

export default Header;
