import React from "react";
import {ReactComponent as CloseButton} from "../img/close.svg";
import {ReactComponent as LogoBosapin} from "../img/logo-bosapin.svg";
import {ReactComponent as IconFeaturing} from "../img/featuring.svg";
import SapinStJean from "../img/sap-st-jean.png";

export default class Popup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {animation: 'none'};
        this.state = {show: true}
    }

    componentWillReceiveProps(props) {
        this.showModal();
    }

    componentDidMount() {
        setTimeout(this.showModal, 200);
    }

    showModal = () => {
        this.setState({show: 'true'});
        setTimeout(() => {
            this.setState({animation: 'slide-in'});
            window.history.pushState('Autocueillette', 'Title', '/');
        }, 400);
    }

    closeModal = () => {
        this.setState({animation: 'slide-out'});
        setTimeout(() => {
            this.setState({show: 'false'});
            document.querySelector('body').classList.remove('no-scroll');
            document.querySelector('body').classList.add('scroll');
        }, 800);
    }

    render() {
        return (
            <section className="popup" data-show={this.state.show}>
                <div className={'popup-modal'} data-animation={this.state.animation}>
                    <CloseButton onClick={this.closeModal} className={'close-button'}/>
                    <div className="popup-content">
                        <div className={'heading-featuring'}>
                            <LogoBosapin/>
                            <IconFeaturing/>
                            <img src={SapinStJean} width={144} height={132} alt="SapinStJean"/>
                        </div>
                        <p>Sapinière St-Jean devient officiellement Bôsapin autocueillette! Eh oui! Afin de vous offrir une
                            expérience plus complète, il est maintenant possible de venir à la rencontre de nos soldats de
                            bois directement sur le terrain pour cueillir votre sapin.</p>
                    </div>
                </div>
            </section>
        );
    }

}