import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LazyImage from "./LazyImage";
import React from "react";

class InstaFeed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {pictures: []};
        this.picturesItems = [];
    }

    componentDidMount() {
        fetch('/proxy-insta.php').then((response) => {
            response.json().then((json) => {
                this.setState({pictures: json});
                this.state.pictures.forEach((picture, index) => {
                    this.picturesItems.push(<LazyImage key={index} style={{"--index": index}} src={picture} alt=""/>)
                });
                this.forceUpdate();
            });
        });
    }

    render() {
        return(
            <section className='instafeed'>
                <div className={'carousel-container'}>
                    {this.picturesItems}
                </div>
                <Carousel autoPlay={false} showIndicators={true} showThumbs={false} showArrows={false} showStatus={false}>
                    {this.picturesItems}
                </Carousel>
            </section>
        )
    }
}

export default InstaFeed