import React from "react";
import {ReactComponent as InfoIco} from "../img/info-ico.svg";
import {ReactComponent as Close} from "../img/featuring.svg";

export default class Banner extends React.Component {
  #text;

  constructor(props) {
    super(props);
    this.#text = props.text;
    this.state = {
      open: true
    };
  }

  closeBanner () {
    this.setState({
      open: false
    });
  }

  render() {
    return (
      <section className="banner" data-open={ this.state.open }>
        <InfoIco />
        <p>{ this.#text }</p>
        <Close onClick={() => this.closeBanner()} className={'close'} />
      </section>
    );
  }
}
