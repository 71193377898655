import logo from '../img/bosapin-logo.png';

function Footer(props) {

    return(
      <footer>
          <div className="content-wrapper">
              <div className={'logo-wrapper'}>
                  <img src={logo} alt='' />
              </div>
              <div className={'text-wrapper'}>
                  <p>
                      Bôsapin est fier de vous offrir une expérience traditionnelle sur le terrain.
                      <br/><br/>
                      Il est également possible de commander votre sapin en ligne sur <a href="https://www.bosapin.com/" target={"_blank"}>bôsapin.com</a> pour profiter d’un service de livraison unique!
                  </p>
              </div>
          </div>
      </footer>
    );
}

export default Footer;