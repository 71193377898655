import Map from '../img/map.png';

function FindTree(props) {

    return (
        <section className={'map-and-tarification'}>
            <span className={'anchor'} id={'tarification'}></span>
            <div className={'map'}>
                <img src={Map} alt=""/>
            </div>
            <div className={'tarification'}>
                <div className="tarif-content">
                    <h2>Tarification</h2>
                    <h5>
                        Sapins de 6 pieds et moins&nbsp;: 55&nbsp;$ taxes incluses.
                        <br/>
                        Sapins de 7 pieds et plus&nbsp;: 69&nbsp;$ taxes incluses.
                    </h5>
                    <p>Argent comptant, crédit et débit. Entrée sur le site gratuite!</p>
                    {/*
                    <h4>HORAIRE</h4>
                    <h5>Ouvert du 25 novembre au 10 décembre.<br/>Vendredi à Dimanche, de 9&nbsp;h à 16&nbsp;h&nbsp;</h5>
                    */}
                </div>
            </div>
            <a href={'https://maps.app.goo.gl/KT6m9AafAdNYuLRDA'}
               target={'_blank'} className={'get-direction'}>OBTENIR L'ITINÉRAIRE</a>
        </section>
    );
}

export default FindTree;
