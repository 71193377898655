import React, {Suspense} from "react";
import Navigation from "./Navigation";
import Autopick from "./Autopick";
import FindTree from "./FindTree";
import MapAndTarification from "./MapAndTarification";
import Steps from "./Steps";
import InstaFeed from "./InstaFeed";


class MainContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loaded: false};
    }

    componentDidMount() {
        this.setState({loaded: true});
    }

    render() {
        return (
            <main data-loaded={this.state.loaded}>
                <Navigation dataShow={this.state.loaded}/>
                <Autopick/>
                <Steps/>
                {/*<InstaFeed/>*/}
                <FindTree/>
                <MapAndTarification/>
            </main>
        )
    }
}

export default MainContent;