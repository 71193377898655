import React from "react";
import step1 from '../img/step1.png';
import step2 from '../img/step2.png';
import step3 from '../img/step3.png';
import step4 from '../img/step4.png';

class Steps extends React.Component {

    constructor(props) {
        super(props);
        this.state = { stepMinHeight: false, loaded: false };
    }

    componentDidMount() {
        setTimeout(() => {
            let steps = document.querySelectorAll('.steps-wrapper > .step-text');
            let finalH = 0;
            steps.forEach((step) => {
                let stepMH = parseFloat(window.getComputedStyle(step).getPropertyValue('height'));
                finalH = stepMH > finalH ? stepMH : finalH;
            });
            this.setState({stepMinHeight: `${finalH}px`, loaded: true });
        }, 300);
    }

    showItsImg(index, bubble = false) {
        if (bubble) {
            this.showItsStep(index);
        }

        let pictures = document.querySelectorAll('.pictures-wrapper > img');
        pictures.forEach((picture) => {
            picture.setAttribute('data-show', 'false');
        });
        let currentPic = document.querySelector(`.pictures-wrapper > img:nth-of-type(${index})`);
        currentPic.setAttribute('data-show', 'true');
    }

    showItsStep(index, bubble = false) {
        if (bubble) {
            this.showItsImg(index);
        }

        let steps = document.querySelectorAll('.steps-wrapper > .step-text');
        steps.forEach((step) => {
            step.setAttribute('data-show', 'false');
        });
        let currentStep = document.querySelector(`.steps-wrapper > .step-text:nth-of-type(${index})`);
        currentStep.setAttribute('data-show', 'true');
    }

    render() {
        return(
            <section className={'steps'} data-loaded={this.state.loaded}>
                <span className={'anchor'} id={'autocueillette'}></span>
                <div className="steps-wrapper" style={{ minHeight: this.state.stepMinHeight ?? "unset"}}>
                    <div style={{ minHeight: this.state.stepMinHeight ?? "unset"}} data-show={'true'} onMouseEnter={() => this.showItsImg(1, true)} className={'step-text'}>
                        <h5 className={'step-number'}>1</h5>
                        <h6 className={'step-content'}>Réservez votre sapin sur notre site internet.</h6>
                    </div>
                    <div style={{ minHeight: this.state.stepMinHeight ?? "unset"}} data-show={'false'} onMouseEnter={() => this.showItsImg(2, true)} className={'step-text'}>
                        <h5 className={'step-number'}>2</h5>
                        <h6 className={'step-content'}>Dirigez-vous vers l’entrée, où l’un de nos soldats de bois vous accueillera chaleureusement.</h6>
                    </div>
                    <div style={{ minHeight: this.state.stepMinHeight ?? "unset"}} data-show={'false'} onMouseEnter={() => this.showItsImg(3, true)} className={'step-text'}>
                        <h5 className={'step-number'}>3</h5>
                        <h6 className={'step-content'}>Rendez-vous maintenant sur notre plantation : scie à la main, vous pourrez alors choisir l’arbre qui vous plaît le plus parmi tous ceux qui vous entourent.</h6>
                    </div>
                    <div style={{ minHeight: this.state.stepMinHeight ?? "unset"}} data-show={'false'} onMouseEnter={() => this.showItsImg(4, true)} className={'step-text'}>
                        <h5 className={'step-number'}>4</h5>
                        <h6 className={'step-content'}>Ramenez votre sapin à l’aide d’un petit traîneau et installez-le sur votre voiture (notez qu’il est possible de faire emballer son arbre pour faciliter le transport).</h6>
                    </div>
                </div>
                <div className="pictures-wrapper">
                    <img data-show={'true'} src={step1} alt=""/>
                    <img data-show={'false'} src={step2} alt=""/>
                    <img data-show={'false'} src={step3} alt=""/>
                    <img data-show={'false'} src={step4} alt=""/>
                </div>
                <div className="steps-mobile-selector-wrapper">
                    <button className={'step-number'} onClick={() => this.showItsStep(1, true)}>1</button>
                    <button className={'step-number'} onClick={() => this.showItsStep(2, true)}>2</button>
                    <button className={'step-number'} onClick={() => this.showItsStep(3, true)}>3</button>
                    <button className={'step-number'} onClick={() => this.showItsStep(4, true)}>4</button>
                </div>
            </section>
        )
    }
}

export default Steps